import React          from 'react';
import { graphql }    from 'gatsby';
import { GiMetalBar } from 'react-icons/gi';

import Wrapper      from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Spacer       from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Heading      from '@interness/web-core/src/components/text/Heading/Heading';
import CallToAction from '@interness/theme-sonora/src/components/CallToAction/CallToAction';

const MaterialkundePage = (props) => {
  return (
    <Wrapper>
      <Spacer/>
      <Heading icon={<GiMetalBar/>} subtitle="Ihre Fragen von uns beantwortet">Materialkunde</Heading>
      <p>Es gibt eine Vielzahl von Materialien, aus denen Trauringe hergestellt werden können. Hier sind einige der
        gängigsten Materialien:</p>
      <p>Gold: Gold ist eines der beliebtesten Materialien für Trauringe. Es ist langlebig, korrosionsbeständig und
        behält seinen Glanz über lange Zeit. Goldringe sind in verschiedenen Farben erhältlich, darunter Gelbgold,
        Weißgold und Roségold.</p>
      <p>Platin: Platin ist ein edles Metall, das für seine Haltbarkeit und Schönheit bekannt ist. Platinringe sind
        besonders widerstandsfähig gegen Kratzer und Verblassen und gelten als eine der hochwertigsten Optionen für
        Trauringe.</p>
      <p>Silber: Silber ist ein erschwinglicheres Material im Vergleich zu Gold und Platin. Es hat einen natürlichen
        Glanz und ist recht beständig gegen Korrosion. Silberringe sind eine beliebte Wahl für Paare mit einem engeren
        Budget.</p>
      <p>Titan: Titan ist ein leichtes und robustes Metall, das in den letzten Jahren an Beliebtheit gewonnen hat.
        Titanringe sind hypoallergen, korrosionsbeständig und kratzfest. Sie sind eine gute Option für Menschen mit
        empfindlicher Haut oder Allergien.</p>
      <p>Edelstahl: Edelstahl ist ein preisgünstiges Material, das eine hohe Haltbarkeit aufweist. Es ist rostbeständig,
        kratzfest und pflegeleicht. Edelstahlringe sind in verschiedenen Stilen und Designs erhältlich.</p>
      <p>Palladium: Palladium ist ein Metall, das ähnliche Eigenschaften wie Platin aufweist, aber zu einem niedrigeren
        Preis erhältlich ist. Es ist leicht, langlebig und beständig gegen Verfärbungen. Palladiumringe sind eine gute
        Alternative zu Platin- ringen.</p>
      <p>Diese sind nur einige der gängigsten Materialien für Trauringe. Andere Optionen umfassen Keramik, Holz,
        Kohlefaser und sogar Verbundmaterialien. Bei der Auswahl der Materialien für Ihre Trauringe ist es wichtig, Ihre
        persönlichen Vorlieben, Ihren Lebensstil und Ihr Budget zu berücksichtigen.</p>
      {/*<Accordion allowToggle>*/}
      {/*  {material.map(item => {*/}
      {/*    if (item.status === 'published') {*/}
      {/*      return (*/}
      {/*        <AccordionItem sx={{*/}
      {/*          h2: {*/}
      {/*            margin: '10px 0'*/}
      {/*          },*/}
      {/*          svg: {*/}
      {/*            color: color*/}
      {/*          }*/}
      {/*        }}>*/}
      {/*          <h2>*/}
      {/*            <AccordionButton>*/}
      {/*              <Box flex="1" textAlign="left">*/}
      {/*                <b>{item.title}</b>*/}
      {/*              </Box>*/}
      {/*              <AccordionIcon/>*/}
      {/*            </AccordionButton>*/}
      {/*          </h2>*/}
      {/*          <AccordionPanel pb={4}>*/}
      {/*            <div dangerouslySetInnerHTML={{ __html: item.content }}/>*/}
      {/*          </AccordionPanel>*/}
      {/*        </AccordionItem>*/}
      {/*      )*/}
      {/*    }*/}
      {/*  })}*/}
      {/*</Accordion>*/}
      <Spacer/>
      <CallToAction description="Vereinbaren Sie noch heute Ihren Termin zur Trauringberatung"
                    button="Zur Terminvereinbarung" link="/terminvereinbarung"
                    imageFileTitle="theme-sonora-call-to-action-background-trauringe"/>
      <Spacer/>
    </Wrapper>
  )
};

export default MaterialkundePage;

export const query = graphql`
    query {
        material: allDirectusWeddingRingMaterials(filter: {id: {ne: "dummy"}}) {
            nodes {
                title
                status
                content
            }
        }
    }
`;
